const serverCheck = {
  getServer: function () {
    const nonProdLoginUrl =
      "https://tms-sso-np.auth.eu-west-2.amazoncognito.com";
    const nonProdLoginClientId = "589u0q8eh8b3kkt4opr1qen9i4";

    const prodLoginUrl =
      "https://tms-sso-prod.auth.eu-west-2.amazoncognito.com";
    const prodLoginClientId = "1iqk04kl8d37jtu284n18ovma0";

    let serverAdministration = "";
    let serverCustomer = "";
    let serverJobBooking = "";
    let serverLoginUrl = "";
    let serverLoginClientId = "";
    let parcelsWmsUrl = "";
    let parcelsAppUrl = "";

    switch (window.location.origin) {
      case location.protocol + "//tms.speedyfreight.com":
        serverAdministration = `${location.protocol}//admin.tms.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//customer.tms.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//qpjb.tms.speedyfreight.com/`;
        serverLoginUrl = prodLoginUrl;
        serverLoginClientId = prodLoginClientId;
        parcelsWmsUrl = "https://wms.speedyfulfil.com/";
        parcelsAppUrl = "https://app.speedyfulfil.com/";
        break;

      case location.protocol + "//tms-fe-uat.apps.speedyfreight.com":
        serverAdministration = `${location.protocol}//tms-admin-be-uat.apps.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//tms-customer-be-uat.apps.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//tms-qpjb-be-uat.apps.speedyfreight.com/`;
        serverLoginUrl = nonProdLoginUrl;
        serverLoginClientId = nonProdLoginClientId;
        parcelsWmsUrl = "https://wms.uat.speedyfulfil.com/";
        parcelsAppUrl = "https://app.uat.speedyfulfil.com/";
        break;

      case location.protocol + "//tms-fe-pp.apps.speedyfreight.com":
        serverAdministration = `${location.protocol}//tms-admin-be-pp.apps.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//tms-customer-be-pp.apps.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//tms-qpjb-be-pp.apps.speedyfreight.com/`;
        serverLoginUrl = nonProdLoginUrl;
        serverLoginClientId = nonProdLoginClientId;
        break;

      case location.protocol + "//tms-fe-dev.apps.speedyfreight.com":
        serverAdministration = `${location.protocol}//tms-admin-be-dev.apps.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//tms-customer-be-dev.apps.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//tms-qpjb-be-dev.apps.speedyfreight.com/`;
        serverLoginUrl = nonProdLoginUrl;
        serverLoginClientId = nonProdLoginClientId;
        parcelsWmsUrl = "https://wms.dev.speedyfulfil.com/";
        parcelsAppUrl = "https://app.dev.speedyfulfil.com/";
        break;

      case location.protocol + "//tms-fe-dev-utc.apps.speedyfreight.com":
        serverAdministration = `${location.protocol}//tms-admin-be-dev-utc.apps.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//tms-customer-be-dev-utc.apps.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//tms-qpjb-be-dev-utc.apps.speedyfreight.com/`;
        serverLoginUrl = nonProdLoginUrl;
        serverLoginClientId = nonProdLoginClientId;
        break;

      case location.protocol + "//tms-dev.eks.apps.speedyfreight.com":
        serverAdministration = `${location.protocol}//admin-dev-a.eks.apps.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//customers-dev-a.eks.apps.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//qpjb-dev-a.eks.apps.speedyfreight.com/`;
        serverLoginUrl = nonProdLoginUrl;
        serverLoginClientId = nonProdLoginClientId;
        break;

      case location.protocol + "//tms-uat.eks.apps.speedyfreight.com":
        serverAdministration = `${location.protocol}//admin-uat-a.eks.apps.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//customers-uat-a.eks.apps.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//qpjb-uat-a.eks.apps.speedyfreight.com/`;
        serverLoginUrl = nonProdLoginUrl;
        serverLoginClientId = nonProdLoginClientId;
        break;

      case location.protocol + "//tms-eks.tms.speedyfreight.com":
        serverAdministration = `${location.protocol}//administration-backend-eks.tms.speedyfreight.com/`;
        serverCustomer = `${location.protocol}//customers-backend-eks.tms.speedyfreight.com/`;
        serverJobBooking = `${location.protocol}//qpjb-backend-eks.tms.speedyfreight.com/`;
        serverLoginUrl = prodLoginUrl;
        serverLoginClientId = prodLoginClientId;
        break;

      default:
        serverAdministration = `${location.protocol}//${location.hostname}:3000/`;
        serverCustomer = `${location.protocol}//${location.hostname}:3001/`;
        serverJobBooking = `${location.protocol}//${location.hostname}:3002/`;
        // This will just about always need to be set to nonProd, but can be overridden if needed
        serverLoginUrl = nonProdLoginUrl;
        serverLoginClientId = nonProdLoginClientId;
        parcelsWmsUrl = "https://wms.dev.speedyfulfil.com/";
        parcelsAppUrl = "https://app.dev.speedyfulfil.com/";
    }

    return {
      serverAdministration,
      serverCustomer,
      serverJobBooking,
      serverLoginUrl,
      serverLoginClientId,
      parcelsWmsUrl,
      parcelsAppUrl
    };
  }
};

export default serverCheck;
